import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { UserInfo } from '../../models/UserInfo';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { ModelService } from '../../services/model.service';
import { SpinnerService } from '../../services/spinner.service';
import { AdUserService } from '../../services/ad-user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userProfile : UserInfo[] = [];
  loginForm: FormGroup;
  IsNativeLogin = environment.isNativeLogin;
  constructor(private _fb: FormBuilder,private router: Router, private modelService:ModelService,
    private route: ActivatedRoute,
    private http: HttpClient,private spinner:SpinnerService,private adUserService:AdUserService,
    private msalService: MsalService ) {
    this.loginForm = this._fb.group({
      userName: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    })
   }

  ngOnInit(): void {
    // const account = this.msalService.instance.getAllAccounts()[0];
    // this.validateUser();  

    if(!environment.isNativeLogin){
    const account = this.msalService.instance.getAllAccounts()[0];
    // debugger;
    if (account) {
      // debugger;
      if (account.idTokenClaims?.preferred_username)     
        this.modelService.userInfoObj.email = account.idTokenClaims.preferred_username;
      if(account.idTokenClaims?.name) 
          //this.modelService.userInfoObj.userName = account.idTokenClaims.name;
          this.modelService.userInfoObj.userName = this.modelService.userInfoObj.email.split('.')[0];
          this.modelService.userInfoObj.userRole  = 'REQ'
         
        this.validateUser();         
        //this.validateUserInGroups();
      } else {
        Swal.fire({
          title: 'Login Failed',
          text: "User is not authorised to access this application, please contact your system administrator",
          icon: 'warning',
          confirmButtonColor: '#103355',
          confirmButtonText: 'OK'
        }).then((result:any) => {
          if (result.isConfirmed) {
            this.router.navigate(['/login']);
          }
        })
      }}
    }
  
  onLogin() {
     this.spinner.showSpinner(); 
    this.http.get<any>("assets/json/users.json").subscribe((data:any)=>{
      if(data){
        // console.log(data)
        this.spinner.stopSpinner();
          this.userProfile = data;
          let filterUserData = this.userProfile.filter(x => x.email?.toUpperCase() == this.loginForm.value.userName.toUpperCase())
          if(filterUserData.length > 0)
          {
            this.modelService.setUserInfo(filterUserData)
             // Retrieve the stored redirect URL
             const redirectUrl = sessionStorage.getItem('redirectUrl');
             sessionStorage.removeItem('redirectUrl');
             if(redirectUrl){
                this.router.navigateByUrl(redirectUrl);
             }else{
                this.router.navigate(['/dashboard']);
            }
          }
          else{
            Swal.fire({
              title: 'Login Failed',
              text: "User ( " + this.loginForm.value.userName + " ) is not authorised to access this application, please contact your system administrator",
              icon: 'warning',
              confirmButtonColor: '#103355',
              confirmButtonText: 'OK'
            }).then((result:any) => {
              if (result.isConfirmed) {
               this.router.navigate(['/login']);
              }
            })
          }        
        }
      }
    );

  }

  validateUser(){
    this.adUserService.searchAduserByEmail(this.modelService.userInfoObj.email).subscribe({
      next:(value:any)=>{
        if(value){
          console.log('ad-user value' , value);
          
          this.modelService.userInfoObj.email= value.email;
          this.modelService.userInfoObj.userName = value.givenName;
          this.modelService.userInfoObj.userRole=value.role;
          this.modelService.userInfoObj.isAdmin = value.isAdmin;
           // this.getUserRole(userEmail);
           // Retrieve the stored redirect URL
            const redirectUrl = sessionStorage.getItem('redirectUrl');
            sessionStorage.removeItem('redirectUrl');
          if(redirectUrl){
            this.router.navigateByUrl(redirectUrl);
          }
          else{
            this.router.navigate(['/dashboard']);
          }
        }else{
          Swal.fire({
                  title: 'Login Failed',
                  text: "User is not authorised to access this application, please contact your system administrator",
                  icon: 'warning',
                  confirmButtonColor: '#103355',
                  confirmButtonText: 'OK'
                }).then((result:any) => {
                  if (result.isConfirmed) {
                    localStorage.clear();  
                    this.msalService.logout();
                  }
                })
          }
        
      },
      error:(err:any)=>{
        console.log('error', err);
      }
    })
  }

}
