
export class UserInfo {
    email : string ;
    userName : string ;
    accessGroup: string ;
    assignedBranch : string ;
    userRole : string ;
    givenName!: string;
    isAdmin:boolean;

  constructor(email: string='', userName: string='',accessGroup:string='', assignedBranch: string='', userRole: string='',isAdmin:boolean=false) {
    this.email = email;
    this.userName = userName;
    this.accessGroup = accessGroup;
    this.assignedBranch = assignedBranch;
    this.userRole = userRole;
    this.isAdmin = isAdmin;
  }
}
